import i18n from '@/locales/index.js'
const { t } = i18n.global

export const rules = {
  username: [
    {
      required: true,
      message: t('login.verifyFormat.username'),
      trigger: ['blur', 'change']
    }
  ],
  password: [
    {
      required: true,
      message: t('login.verifyFormat.password'),
      trigger: ['blur', 'change']
    }
  ],
  captcha_text: [
    {
      required: true,
      message: t('login.verifyFormat.captcha_text'),
      trigger: ['blur', 'change']
    }
  ]
}
