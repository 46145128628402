import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-a1fc1840"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "login"
};
const _hoisted_2 = {
  class: "container-hearder"
};
const _hoisted_3 = {
  class: "container-box"
};
const _hoisted_4 = {
  class: "switch-lang"
};
const _hoisted_5 = {
  class: "bottom-copyright"
};
import FormLogo from '@/components/form-logo';
import SwitchLang from '@/components/switch-lang';
import BottomCopyright from '@/components/bottom-copyright';
import LoginPanel from './cpns/login-panel.vue';
export default {
  __name: 'login',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(FormLogo))]), _createElementVNode("div", _hoisted_3, [_createVNode(LoginPanel)]), _createElementVNode("div", _hoisted_4, [_createVNode(_unref(SwitchLang))]), _createElementVNode("div", _hoisted_5, [_createVNode(_unref(BottomCopyright))])]);
    };
  }

};